import LeftBanner from "../../../assets/images/home/leftBanner.png";
import React from "react";
import RightBanner from "../../../assets/images/home/rightBanner.png";

function HomeBottomBanner() {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "5%",
        height: "13.9%",
        width: "70%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "49%",
          height: "100%",
          float: "left",
        }}
      >
        <img
          src={LeftBanner}
          style={{ flex: 1, height: "100%" }}
          alt="banner"
        />
      </div>

      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "49%",
          float: "right",
          borderLeftColor: "#FFFFFF",
          borderLeftWidth: 2,
          height: "100%",
          borderLeftStyle: "solid",
        }}
      >
        <img
          src={RightBanner}
          style={{ flex: 1, height: "100%", marginLeft: 50 }}
          alt="banner"
        />
      </div>
    </div>
  );
}

export default HomeBottomBanner;
