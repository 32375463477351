import "./style.css";

import IntroImage1 from "../../../assets/images/introduce/introImg1.png";
import IntroImage2 from "../../../assets/images/introduce/introImg2.png";
import IntroImage3 from "../../../assets/images/introduce/introImg3.png";
import React from "react";

function IntroImageContainer() {
  return (
    <div className="introImageContainer">
      <div className="imageWrapper">
        <img
          style={{
            width: "86%",
          }}
          src={IntroImage2}
          alt="IntroImage2"
        />
      </div>
      <div className="imageSpacing imageWrapper">
        <img
          style={{
            width: "86%",
          }}
          src={IntroImage1}
          alt="IntroImage1"
        />
        <img
          style={{
            width: "86%",
          }}
          src={IntroImage3}
          alt="IntroImage3"
        />
      </div>
    </div>
  );
}

export default IntroImageContainer;
