import React from 'react';

function IntroDescription() {
    return (
        <div
            style={{
                flex: 1,
                width: '40%',
            }}
        >
            <div style={{ width: '100%', marginBottom: '10%' }}>
                <span style={{ fontSize: 42, color: '#FFFFFF' }}>
                    <strong>한국 여행 동안</strong>
                </span>
                <br />
                <span style={{ fontSize: 42, color: '#FFFFFF' }}>
                    <strong>사진으로 구매</strong>부터 <strong>수령</strong>까지
                </span>
                <br />
                <span style={{ fontSize: 42, color: '#FFFFFF' }}>
                    도와드릴게요.
                </span>
                <br />
                <span style={{ fontSize: 42, color: '#FFFFFF' }}>
                    시간, 무거운 짐, 언어, 길
                </span>
                <br />
                <span style={{ fontSize: 42, color: '#FFFFFF' }}>
                    다 걱정 하지마세요!
                </span>
            </div>

            <div style={{ width: '100%' }}>
                <span style={{ fontSize: 27, color: '#FFFFFF' }}>
                    인천공항 / 고객님의 호텔로{' '}
                </span>
                <br />
                <span style={{ fontSize: 27, color: '#FFFFFF' }}>
                    직접 가져다 드립니다.
                </span>
                <br />
                <span style={{ fontSize: 18, color: '#FFFFFF' }}>
                    * 단, 서울지역 호텔에 한하여
                </span>
            </div>
        </div>
    );
}

export default IntroDescription;
