import "./style.css";

import ButtonImg from "../../assets/images/fixButton/fixBtn.png";
import React from "react";

function FixButton() {
  return (
    <a
      className="fixBtnWrapper"
      href="https://apps.apple.com/us/app/photosend/id1491157337"
      target="blank"
    >
      <img className="fixBtnImg" src={ButtonImg} alt="Download_Button" />
    </a>
  );
}

export default FixButton;
