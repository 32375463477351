import "./style.css";

import React from "react";
import bye from "../../assets/images/background/bye_back.png";
import home from "../../assets/images/background/home_back.png";

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

function PageWrapper({ children, ...props }) {
  return props.backgroundImage ? (
    <div
      className="PageWrapper"
      style={{
        width: windowWidth,
        height: windowHeight,
        backgroundColor: props.color,
        backgroundImage: props.page === "bye" ? bye : bye,
        alignItems: "center",
      }}
    >
      {props.page === "home" ? (
        <img
          className="backgroundImage"
          src={home}
          alt="background_image"
          style={{
            width: windowWidth,
            backgroundColor: props.color,
          }}
        />
      ) : (
        <img className="byeBackground" src={bye} alt="background_image" />
      )}

      {children}
    </div>
  ) : (
    <div
      className="PageWrapper"
      style={{
        width: windowWidth,
        height: windowHeight,
        backgroundColor: props.color,
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
}

export default PageWrapper;
