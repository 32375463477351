import "./style.css";

import HowToUse from "../../../assets/images/howToUse/howtouse.png";
import React from "react";

function HowToUseContainer() {
  return (
    <div className="howtoContainer">
      <div className="howtoContainer__layout">
        <div className="howTitleText">'PhotoSend' 사용법</div>
        <img className="howToImage" src={HowToUse} alt="HowToUse" />
      </div>
    </div>
  );
}

export default HowToUseContainer;
