import "./App.css";

import AppContainer from "./Layouts/AppContainer";
import ContentsLayout from "./Layouts/ContentsLayout";
import FixButton from "./components/FixButton";
import Footer from "./components/bye/Footer";
import HeaderWrapper from "./Layouts/HeaderWrapper";
import HomeBottomBanner from "./components/home/HomeBottomBanner";
import HomeTitle from "./components/home/HomeTItle";
import HowToUseContainer from "./components/howToUse/HowToUseContainer";
import IntroDescription from "./components/introduce/IntroDescription";
import IntroImageContainer from "./components/introduce/IntroImageContainer";
import PageWrapper from "./Layouts/PageWrapper";
import ProductContainer from "./components/products/ProductContainer";
import React from "react";
import ReviewContainer from "./components/review/ReviewContainer";

function App() {
  return (
    <AppContainer>
      <HeaderWrapper />
      <PageWrapper backgroundImage={true} page="home">
        <ContentsLayout>
          <HomeTitle />
          <HomeBottomBanner />
        </ContentsLayout>
      </PageWrapper>
      <PageWrapper backgroundImage={false} page="introduce" color="#6b1de5">
        <ContentsLayout>
          <IntroDescription />
          <IntroImageContainer />
        </ContentsLayout>
      </PageWrapper>
      <PageWrapper backgroundImage={false} page="how" color="white">
        <ContentsLayout>
          <HowToUseContainer />
        </ContentsLayout>
      </PageWrapper>
      <PageWrapper backgroundImage={false} page="products" color="#FFFFFF">
        <ContentsLayout>
          <ProductContainer />
        </ContentsLayout>
      </PageWrapper>
      <PageWrapper backgroundImage={false} page="reviews" color="#FFFFFF">
        <ContentsLayout>
          <ReviewContainer />
        </ContentsLayout>
      </PageWrapper>
      <PageWrapper backgroundImage={true} page="bye">
        <ContentsLayout>
          <Footer />
        </ContentsLayout>
      </PageWrapper>
      <FixButton />
    </AppContainer>
  );
}

export default App;
