import "./style.css";

import HeaderSNSButtons from "../../components/header/HeaderSNSButtons";
import React from "react";
import logo from "../../assets/images/header/logo.png";

function HeaderWrapper({ children }) {
  return (
    <div className="headerWrapper">
      <a className="header__logoLayout" href="./">
        <img className="header__logo" src={logo} alt="PhotoSend_logo" />
      </a>
      <HeaderSNSButtons />
    </div>
  );
}

export default HeaderWrapper;
