import "./style.css";

import React from "react";
import Reviews from "../../../assets/images/review/reviews.png";

function ReviewContainer() {
  return (
    <div className="reviewContainer">
      <div className="reviewContainer__layout">
        <div className="reviewTitleText__layout">
          <div className="reviewTitleText">생생 고객 리뷰</div>
          <div className="reviewTitleSubText">
            지금, 생생한 고객님 리뷰를 확인해 보세요.
          </div>
        </div>

        <img className="reviewImage" src={Reviews} alt="reviews" />
      </div>
    </div>
  );
}

export default ReviewContainer;
