import "./style.css";

import React from "react";
import product_1 from "../../../assets/images/products/products_1.png";
import product_2 from "../../../assets/images/products/products_2.png";

function ProductContainer({ title, prod }) {
  return (
    <div className="productContainer">
      <div className="productContainer__layout">
        <div className="productContainer__titleLayout">
          <span className="productContainer__title">추천패키지 TOP 5</span>
          <span className="productContainer__subtitle">
            요청 외에도 추천드릴테니 골라서 추가 주문하실 수 있어요
          </span>
        </div>
        <img
          className="productContainer__image"
          src={product_1}
          alt="product_1"
        />
      </div>
      <div className="productContainer__layout layoutSpacing">
        <div className="productContainer__titleLayout">
          <span className="productContainer__titleSticker">NEW</span>
          <span className="productContainer__title">실시간 업로드</span>
          <span className="productContainer__subtitle">
            요청 외에도 추천드릴테니 골라서 추가 주문하실 수 있어요
          </span>
        </div>
        <img
          className="productContainer__image"
          src={product_2}
          alt="product_2"
        />
      </div>
    </div>
  );
}

export default ProductContainer;
