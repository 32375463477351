import React from "react";

function HomeTitle() {
  return (
    <div
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ fontSize: 86, fontWeight: "bold", color: "white" }}>
        한국을
      </span>
      <br />
      <span style={{ fontSize: 86, fontWeight: "bold", color: "white" }}>
        두 손 자유롭게 즐기자
      </span>
    </div>
  );
}

export default HomeTitle;
