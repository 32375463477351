import "./style.css";

import React from "react";
import facebook from "../../../assets/images/header/facebook_icon.png";
import insta from "../../../assets/images/header/insta_icon.png";
import weibo from "../../../assets/images/header/weibo_icon.png";

const SNSButton = ({ ...props }) => {
  return (
    <a className="buttonWrapper" href={props.snsLink}>
      <img
        className="buttonImage"
        src={props.imagePath}
        alt={props.name}
        target="blank"
      />
    </a>
  );
};
const HeaderSNSButtons = () => {
  return (
    <span className="wrapper">
      <SNSButton
        imagePath={weibo}
        snsLink="https://www.weibo.com/7329622899/profile?rightmod=1&wvr=6&mod=personinfo"
      />
      <SNSButton
        imagePath={facebook}
        snsLink="https://www.facebook.com/photosendkr/?modal=admin_todo_tour"
      />
      <SNSButton
        imagePath={insta}
        snsLink="https://www.instagram.com/photosend_kr/?hl=ko"
      />
    </span>
  );
};

export default HeaderSNSButtons;
